import React, { useLayoutEffect, useMemo, useRef } from 'react';


import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import Layout from '@layouts/Layout';


/** Checkout Page */
const Checkout = () => {
    const querystring = (typeof window !== 'undefined') ? queryString.parse(window.location.search) : {};
    const formRef = useRef();

    const {
        PaReq, TermUrl, MD, RedirectURL
    } = useMemo(() => (querystring.token ? jwtDecode(querystring.token) : {}), []);

    useLayoutEffect(() => {
        formRef.current.submit();
    }, [PaReq, TermUrl, MD, RedirectURL]);

    return (
        <Layout mainClassName="flow--xlarge" metaData={{ title: 'Checkout Page' }}>
            <header className="hero background-color--chalk ">
                <div className="container flow text-align--center">
                    <h1 className="text-page-title">Redirecting...</h1>
                </div>
            </header>

            <section className="wrapper container container--small flow--medium  flow--skip">
                This page should forward you to your own card issuer for
                identification. If your browser does not start loading the
                page, press the Submit button. <br /><br />
                After you successfully identify yourself you will be sent back
                to this website, where the payment process will continue.<br />
                <form ref={formRef} name="theForm" method="POST" action={RedirectURL}>
                    <input type="hidden" name="PaReq" value={PaReq} />
                    <input type="hidden" name="TermUrl" value={TermUrl} />
                    <input type="hidden" name="MD" value={MD} />
                    <input type="submit" className="button button--default" name="Identify yourself" />
                </form>
            </section>

        </Layout>
    );
};

export default Checkout;
